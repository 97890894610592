import filesize from 'filesize';

export const exponentialCol = {
    type: 'number',
    valueFormatter: (params) => {
      if (params.value !== undefined && params.value !== null) {
        return `${params.value.toExponential(2)}`;
      }
      return ''
    },
  };

export const coordinateCol = {
    type: 'number',
    width: 110,
    valueFormatter: (params) => {
      if (params.value !== undefined && params.value !== null) {
        return `${params.value.toFixed(4)}`;
      }
      return ''
    },
  };

export const galaxyListColumns = [
    {
      field: 'id',
      headerName: 'ID',
      description: 'Internal ID for this object',
      type: 'number',
      width: 100
    },
    {
      field: 'ned_name',
      headerName: 'Common Name',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'objra',
      headerName: 'RA',
      description: 'Right ascension of the science object in J2000 (degrees)',
      type: 'number',
      ...coordinateCol
    },
    {
      field: 'objdec',
      headerName: 'Dec',
      description: 'Declination of the science object in J2000 (degrees)',
      type: 'number',
      ...coordinateCol
    },
    {
      field: 'plateifu',
      headerName: 'PlateIFU',
      description: 'Plate+ifudesign name for this object',
    },
    {
      field: 'mangaid',
      headerName: 'MaNGA-ID',
      description: 'MaNGA ID for this object (e.g. 1-114145)'
    },
    {
      field: 'megacube',
      headerName: 'Megacube',
      description: 'Megacube File Name.',
      minWidth: 200,
    },
    {
      field: 'size',
      headerName: 'Fits Size',
      valueFormatter: (params) => {
        if (params.value !== null) {
          return `${filesize(params.value)}`;
        }
        return ''
      },
    },
    {
      field: 'compressed_size',
      headerName: 'Compressed Size',
      valueFormatter: (params) => {
        if (params.value !== null) {
          return `${filesize(params.value)}`;
        }
        return ''
      },
    },
    {
      field: 'had_bcomp',
      headerName: 'B. Comp',
      description: 'Indicates whether the object has Broad component attribute.',
      type: 'boolean',
    },
    {field:'fcfc1_50', ...exponentialCol },
    {field:'xyy_light', ...exponentialCol },
    {field:'xyo_light', ...exponentialCol },
    {field:'xiy_light', ...exponentialCol },
    {field:'xii_light', ...exponentialCol },
    {field:'xio_light', ...exponentialCol },
    {field:'xo_light', ...exponentialCol },
    {field:'xyy_mass', ...exponentialCol },
    {field:'xyo_mass', ...exponentialCol },
    {field:'xiy_mass', ...exponentialCol },
    {field:'xii_mass', ...exponentialCol },
    {field:'xio_mass', ...exponentialCol },
    {field:'xo_mass', ...exponentialCol },
    {field:'sfr_1', ...exponentialCol },
    {field:'sfr_5', ...exponentialCol },
    {field:'sfr_10', ...exponentialCol },
    {field:'sfr_14', ...exponentialCol },
    {field:'sfr_20', ...exponentialCol },
    {field:'sfr_30', ...exponentialCol },
    {field:'sfr_56', ...exponentialCol },
    {field:'sfr_100', ...exponentialCol },
    {field:'sfr_200', ...exponentialCol },
    {field:'av_star', ...exponentialCol },
    {field:'mage_l', ...exponentialCol },
    {field:'mage_m', ...exponentialCol },
    {field:'mz_l', ...exponentialCol },
    {field:'mz_m', ...exponentialCol },
    {field:'mstar', ...exponentialCol },
    {field:'sigma_star', ...exponentialCol },
    {field:'vrot_star', ...exponentialCol },
    {field:'f_hb', ...exponentialCol },
    {field:'f_o3_4959', ...exponentialCol },
    {field:'f_o3_5007', ...exponentialCol },
    {field:'f_he1_5876', ...exponentialCol },
    {field:'f_o1_6300', ...exponentialCol },
    {field:'f_n2_6548', ...exponentialCol },
    {field:'f_ha', ...exponentialCol },
    {field:'f_n2_6583', ...exponentialCol },
    {field:'f_s2_6716', ...exponentialCol },
    {field:'f_s2_6731', ...exponentialCol },
    {field:'eqw_hb', ...exponentialCol },
    {field:'eqw_o3_4959', ...exponentialCol },
    {field:'eqw_o3_5007', ...exponentialCol },
    {field:'eqw_he1_5876', ...exponentialCol },
    {field:'eqw_o1_6300', ...exponentialCol },
    {field:'eqw_n2_6548', ...exponentialCol },
    {field:'eqw_ha', ...exponentialCol },
    {field:'eqw_n2_6583', ...exponentialCol },
    {field:'eqw_s2_6716', ...exponentialCol },
    {field:'eqw_s2_6731', ...exponentialCol },
    {field:'v_hb', ...exponentialCol },
    {field:'v_o3_4959', ...exponentialCol },
    {field:'v_o3_5007', ...exponentialCol },
    {field:'v_he1_5876', ...exponentialCol },
    {field:'v_o1_6300', ...exponentialCol },
    {field:'v_n2_6548', ...exponentialCol },
    {field:'v_ha', ...exponentialCol },
    {field:'v_n2_6583', ...exponentialCol },
    {field:'v_s2_6716', ...exponentialCol },
    {field:'v_s2_6731', ...exponentialCol },
    {field:'sigma_hb', ...exponentialCol },
    {field:'sigma_o3_4959', ...exponentialCol },
    {field:'sigma_o3_5007', ...exponentialCol },
    {field:'sigma_he1_5876', ...exponentialCol },
    {field:'sigma_o1_6300', ...exponentialCol },
    {field:'sigma_n2_6548', ...exponentialCol },
    {field:'sigma_ha', ...exponentialCol },
    {field:'sigma_n2_6583', ...exponentialCol },
    {field:'sigma_s2_6716', ...exponentialCol },
    {field:'sigma_s2_6731', ...exponentialCol },
  ];

export const columnVisibilityModel = {
    id: true,
    ned_name: true,
    objra: true,
    objdec: true,
    plateifu: true,
    mangaid: true,
    megacube: true,
    had_bcomp: false,
    compression: false,
    compressed_size: false,
    size: false,
    fcfc1_50: false,
    xyy_light: false,
    xyo_light: false,
    xiy_light: false,
    xii_light: false,
    xio_light: false,
    xo_light: false,
    xyy_mass: false,
    xyo_mass: false,
    xiy_mass: false,
    xii_mass: false,
    xio_mass: false,
    xo_mass: false,
    sfr_1: false,
    sfr_5: false,
    sfr_10: false,
    sfr_14: false,
    sfr_20: false,
    sfr_30: false,
    sfr_56: false,
    sfr_100: false,
    sfr_200: false,
    av_star: false,
    mage_l: true,
    mage_m: true,
    mz_l: true,
    mz_m: true,
    mstar: false,
    sigma_star: false,
    vrot_star: false,
    f_hb: false,
    f_o3_4959: false,
    f_o3_5007: false,
    f_he1_5876: false,
    f_o1_6300: false,
    f_n2_6548: false,
    f_ha: false,
    f_n2_6583: false,
    f_s2_6716: false,
    f_s2_6731: false,
    eqw_hb: false,
    eqw_o3_4959: false,
    eqw_o3_5007: false,
    eqw_he1_5876: false,
    eqw_o1_6300: false,
    eqw_n2_6548: false,
    eqw_ha: false,
    eqw_n2_6583: false,
    eqw_s2_6716: false,
    eqw_s2_6731: false,
    v_hb: false,
    v_o3_4959: false,
    v_o3_5007: false,
    v_he1_5876: false,
    v_o1_6300: false,
    v_n2_6548: false,
    v_ha: false,
    v_n2_6583: false,
    v_s2_6716: false,
    v_s2_6731: false,
    sigma_hb: false,
    sigma_o3_4959: false,
    sigma_o3_5007: false,
    sigma_he1_5876: false,
    sigma_o1_6300: false,
    sigma_n2_6548: false,
    sigma_ha: false,
    sigma_n2_6583: false,
    sigma_s2_6716: false,
    sigma_s2_6731: false,
}
